// App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import Login from './Componnets/Login';
import ExtensionAPi from './Componnets/ExtensionAPi';
import ExtensionURL from './Componnets/ExtensionURL';
import Home from './Componnets/Home';
import AllApi from './Componnets/AllApi';
import AllUrl from './Componnets/AllUrl';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <>
    <div class="sidebar">
    <Link  to="/">Home</Link>
    <Link  to="/exApi">AddApi</Link>
    <Link  to="/exurl">AddUrl</Link>
    <Link  to="/api">AllApi</Link>
    <Link  to="/url">AllUrl</Link>
   
  </div>


  <div class="content">
  <Routes>
        <Route path="/" element={loggedIn ? <Home /> : <Login setLoggedIn={setLoggedIn} />} />
        <Route path="/exApi" element={loggedIn ? <ExtensionAPi /> : <Login setLoggedIn={setLoggedIn} />} />
        <Route path="/api" element={loggedIn ? <AllApi /> : <Login setLoggedIn={setLoggedIn} />} />
        <Route path="/url" element={loggedIn ? <AllUrl /> : <Login setLoggedIn={setLoggedIn} />} />
        <Route path="/exurl" element={loggedIn ? <ExtensionURL /> : <Login setLoggedIn={setLoggedIn} />} />
     
      </Routes>
</div>
     
      </>
  );
}

export default App;
