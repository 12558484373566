import React from 'react';

const Home = () => {
    return (
        <div className='container'>
            <h1 className='text-center'>Wellcome To Microters Extension World</h1>
        </div>
    );
};

export default Home;