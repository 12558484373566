// Post.js

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const ExtensionURL = () => {
  const [extensionInstallUrl, setExtensionInstallUrl] = useState('');
  const [extensionUnInstallUrl, setExtensionUnInstallUrl] = useState('');
  const [extensionName, setExtensionName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://api.fastwpspeed.com/microUrl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          extensionName,
          extensionInstallUrl,
          extensionUnInstallUrl
        })
      });

      if (response.ok) {
        console.log('Post sent successfully');
        toast('Post sent successfully')
        // Optionally, you can reset the form after successful submission
        setExtensionName("")
        setExtensionInstallUrl('');
        setExtensionUnInstallUrl('');
      } else {
        
        toast.error('Failed to send Post to the server')
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className='text-center'>
       
      <h1 className='text-center'>Add Your URL</h1>
      <form onSubmit={handleSubmit} className='form-control'>
        <div>
          <label htmlFor="api">Enter Extension Name:</label>
          <input
            className='form-control'
            type='text'
            id='name'
            name='name'
            value={extensionName}
            onChange={(e) => setExtensionName(e.target.value)}
            placeholder='Enter Extension Name'
          />
        </div>
        <div>
          <label htmlFor="api">Enter Extension InstallUrl:</label>
          <input
            className='form-control'
            type='text'
            id='api'
            name='api'
            value={extensionInstallUrl}
            onChange={(e) => setExtensionInstallUrl(e.target.value)}
            placeholder='Enter Extension InstallUrl'
          />
        </div>
        <div>
          <label htmlFor="apiKey">Enter Extension UnInstallUrl:</label>
          <input
            className='form-control'
            type='text'
            id='apiKey'
            name='apiKey'
            value={extensionUnInstallUrl}
            onChange={(e) => setExtensionUnInstallUrl(e.target.value)}
            placeholder='Enter Extension UnInstallUrl'
          />
        </div>
        <button className='btn btn-success mt-2 w-100' type="submit">POST</button>
       <ToastContainer/>
      </form>
     
      
    </div>
  );
};

export default ExtensionURL;
